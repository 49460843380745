@import './base/bootstrap-extended/include'; // Bootstrap includes
@import './base/components/include'; // Components includes

body {
	--text-color: #000;
	--border-color: hsl(0, 0%, 80%);
	--background-color: #fff;
	--input-text-color: #6e6b7b;
	padding-bottom: env(safe-area-inset-bottom);
}

body,
html,
.content.app-content {
	overflow: hidden;
}

body.dark-layout {
	--text-color: #eee;
	--border-color: #404656;
	--background-color: #283046;
	--input-text-color: #b4b7bd;
}

.table-btn {
	background-color: transparent !important;
	border: none !important;
	padding: 0.5rem 0.7rem;

	&:hover,
	&:focus {
		background-color: transparent !important;
		border: none !important;
		box-shadow: none !important
	}
}

.table-dropdown {
	.dropdown-item {
		width: 100%;
	}
}

.modal .modal-header {
	align-items: center;
}

.modal .modal-header .close {
	transform: translate(0, 0);
	box-shadow: none;
	background: transparent;
	margin: 0;

	&:hover {
		transform: translate(0, 0);

	}
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
}

span.brand-logo.light {
	display: flex;
}

span.brand-logo.dark {
	display: none;
}

// .avatar-photo {
// 	width: 100px;
// 	height: 100px;
// }

.dark-layout {

	.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
		color: #eee !important;
	}

	li.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
		color: $primary !important;
	}

	.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
		color: $primary !important;
	}

	a.ant-picker-now-btn {
		color: #fff !important;
	}

	li.ant-picker-ok button.ant-btn.ant-btn-primary.ant-btn-sm {
		background-color: $primary !important;
		border-color: $primary !important;

		&:hover,
		&:focus {
			background-color: $primary !important;
			border-color: $primary !important;
		}
	}

	[role="table"] {
		background-color: red !important;

		.rdt_TableHeadRow {
			background-color: #171e31 !important;
			color: #fff;
		}

		.rdt_TableRow {
			background-color: #171e31 !important;
			color: #fff;

		}

		.table-btn {
			svg {
				stroke: #6e6b7b !important;
			}
		}
	}

	.react-select>div {
		background-color: #283046 !important;
		border-color: #404656 !important;

		.select__option--is-focused,
		.select__option--is-selected {
			background-color: #171e31 !important;
		}

		.select__single-value {
			color: #b4b7bd !important;
		}
	}

	.uppy-FileInput-btn {
		color: #b4b7bd !important;
		border-color: #b4b7bd !important;

		&:hover {

			background-color: #171e31 !important;
		}
	}

	input:-internal-autofill-selected {
		background-color: #283046 !important;
	}

	.select__control {
		.select__input {

			color: #ddd !important;
		}
	}

	.rdt_Table {
		>div {
			background-color: #171e31 !important;
			color: #ddd;
		}
	}

	.modal-body .d-flex.justify-content-end {
		border-top: 1px solid #171e31;
	}

	.select__placeholder {
		color: $body-color !important;
		opacity: 1;
		font-weight: 400;
	}

	.select__single-value {
		color: $body-color !important;
	}

	.ant-tooltip-inner,
	.ant-tooltip-arrow-content {
		background-color: #171e31 !important;
	}

	span.brand-logo.light {
		display: none;
	}

	span.brand-logo.dark {
		display: flex;
	}

	.logo-light {
		display: none;
	}

	.logo-dark {
		display: inline-block;
	}

	.select__control.select__control--is-disabled {
		opacity: 0.5 !important;
	}

	.disabled.form-group {
		background-color: transparent;
		opacity: 1;

		input.form-control:not(:focus),
		.custom-file-label:not(:focus),
		textarea.form-control:not(:focus) {
			color: #b4b7bd;
			opacity: 0.5 !important;

		}

		.input-group-text {
			background-color: transparent;
			color: #b4b7bd;
			opacity: 0.5;
		}
	}

	.form-group {

		.ant-picker,
		.ant-picker:hover,
		.ant-picker-focused {
			border: 1px solid #404656;
			border-radius: 0.357rem;
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.ant-picker.ant-picker-range.form-control {
			border: 1px solid #404656;
			border-radius: 0.357rem;
		}

		.ant-picker-input>input {
			color: #b4b7bd;
		}

		.ant-picker-separator,
		.ant-picker-suffix {
			color: #b4b7bd;

		}

		.ant-picker-clear {
			background-color: transparent;
		}

		.ant-picker-clear>* {
			color: #b4b7bd;

		}
	}

	.ant-picker-panel-container .ant-picker-panel {
		background-color: #171e31;
	}

	.ant-picker-header {
		border-bottom: 1px solid #283046;
		color: #eee;
	}

	.ant-picker-panel .ant-picker-footer {
		border-bottom: 1px solid #283046;
		border-top: 1px solid #283046;

	}

	.ant-picker-panel {
		border: none;

	}

	.ant-picker-panel-container {

		border-radius: 5px;
		box-shadow: none;
	}

	.ant-picker-header button {
		color: #eee;
	}

	.ant-picker-content th {
		color: #eee;
	}

	.ant-picker-cell.ant-picker-cell-in-view {
		color: #eee;
	}

	.ant-picker-cell {
		color: #686868;

	}

	.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
	.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
		color: #000;
	}

	.ant-picker-today-btn {
		color: #eee;
	}

	.disabled.form-group .input-group-merge .form-control:not(:first-child) {
		background-color: transparent !important;
		border-color: #404656 !important;
	}

	.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
		background: #141d20;
	}

	// .ant-picker-cell,
	// .ant-picker-content th, .ant-picker-content td {
	// 	color: #555 !important;
	// }


}

.upload-card {
	margin-bottom: 0;

	.card-body {
		padding: 1rem;
	}
}

.filter-input {
	display: flex;
	flex: 1;

	.react-select {
		width: 100%;
	}
}

.filter-group {
	label {
		margin: 0 1rem;
	}
}

.rdt_TableCol {
	text-transform: capitalize;
}

.card-header,
.modal-header {

	h5,
	.card-title {
		text-transform: capitalize;

	}
}

.modal-footer {
	button {
		text-transform: capitalize;
	}
}

.form-group {
	label {
		text-transform: capitalize;
	}
}

.nav-item {
	a {
		text-transform: capitalize;

	}
}

.nav-item {
	&.active {
		a {
			&::before {
				background-color: rgb(243, 243, 243);
				color: #000;
			}
		}
	}

	a {
		&::before {
			content: '';
			width: 38px !important;
			height: 38px !important;
			padding: 8px;
			border-radius: 100%;
			position: absolute;
			transition: background 0.5s ease-out;
			left: 0;
			margin: auto;
			right: 0;
			top: 0;
			bottom: 0;
		}

		&:hover::before {
			background: rgb(243, 243, 243);
			color: #000;
		}
	}
}

.breadcrumb-item,
.content-header-title {
	text-transform: capitalize;

}


.table-loading {
	display: flex;
	flex-direction: column;
	padding: 2rem 4rem;
	justify-content: center;
	align-items: center;

	.spinner-grow {
		margin-bottom: 2rem;
	}
}

.modal-body .d-flex.justify-content-end {
	border-top: 1px solid #eee;
}

.select__placeholder {
	color: $body-color !important;
	opacity: .6;
	font-weight: 400;
}

.select__single-value {
	color: black !important;
}

.btn-primary {
	text-transform: capitalize;
}

.btn-small {
	padding: .5rem .5rem;
	width: auto;
	display: inline-block;
	max-width: initial;
	width: 40px;

	&.update-btn {
		width: 75px;
	}
}

.d-flex.justifu-content-end.col-sm-3 {
	display: flex;
	justify-content: center;

	.btn-small {
		margin-bottom: 1rem;
		margin-top: 2rem;
		height: 38px;
	}
}

.action-btns {
	.btn-small {
		margin-top: 1rem;
		margin-left: .5rem;
		margin-right: .5rem;
	}
}

.instalments {
	.btn-small {
		margin-top: 0;
	}
}

.det-row {
	padding: .5rem 0;
}

.btn-transparent {
	padding: 0;
	color: $secondary !important;
}

.form-control[readonly] {
	background-color: initial;
	opacity: 1;
}

.table-btn {
	svg {
		stroke: #6e6b7b;
	}
}

.filter-form-group {
	label {
		margin: 0 -1rem;
	}
}

.table-loading {
	color: $body-color;
}

.ant-skeleton-content .ant-skeleton-paragraph>li {
	height: 40px !important;
}

.ant-skeleton-content .ant-skeleton-paragraph>li+li {
	margin-top: 1px;
}

.modal .modal-header {
	.btn-small {
		padding: .2rem;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

span.input-group-text {
	.btn-small {
		background-color: transparent !important;
		padding: 0;
		border: none;

		&:hover {
			background-color: transparent !important;

		}

		svg {
			stroke: #6e6b7b;
		}
	}
}

// .rdt_TableCol_Sortable {
// 	.lnndaO {
// 		overflow: visible !important;
// 		white-space: break-spaces !important;
// 		text-overflow: initial !important;
// 	}
// }

.rdt_TableCell {
	>span {
		overflow: clip !important;
		white-space: nowrap !important;
		text-overflow: ellipsis !important;
	}
}

.d-flex.p-1.justify-content-end {
	.spinner-border-sm {
		margin: 0 .5rem;
	}
}

.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

a.navbar-brand.active {
	padding: 0 .5rem;
	margin: 0;
}

a.navbar-brand.active .brand-logo {
	width: 100%;
	flex: 1;
}

a.navbar-brand.active .brand-logo img {
	height: 40px;
	transition: all .4s;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
	max-width: 100%;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
	a.navbar-brand.active .brand-logo {
		margin: .5rem -.5rem;

		img {

			width: auto !important;
			// height: 15px !important;
			max-width: 100% !important;
		}
	}
}

.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded {
	a.navbar-brand.active .brand-logo {
		margin: .5rem -.5rem;

		img {

			width: auto !important;
			height: 40px !important;
			max-width: 100% !important;
		}
	}
}

.main-menu .navbar-header .navbar-brand {
	margin-top: 0;
}

// .main-menu .navbar-header {
// 	padding: 1rem .5rem;
// }

.file-delete-btn {
	padding: .5rem 1rem;
	display: flex;
}

.rt-td {
	white-space: normal !important
}

.gPyjSA div:first-child {
	white-space: normal !important
}

button.waves-effect.btn-small.update-btn.btn.btn-primary,
button.waves-effect.btn-small.btn.btn-danger {
	width: auto;

}

label {
	color: #000;
}

li.breadcrumb-item a {
	color: $primary !important;
}

.content-wrapper>.card {
	margin-bottom: .2rem;
}

.uppy-FileInput-btn {
	color: $primary !important;
	border-color: $primary !important;

	&:hover {
		color: #fff !important;
		background-color: $primary !important;
	}
}

.logo-light {
	display: inline-block;
}

.logo-dark {
	display: none;
}

.disabled.form-group {
	.input-group-merge .form-control:not(:first-child) {
		background-color: #efefef !important;
		opacity: 1;
		border-color: #d8d6de !important;

	}

	.input-group-text {
		background-color: #efefef;
	}
}

.react-select {
	border-radius: 0.357rem
}

.react-select.is-invalid {
	.select__control {

		border: 1px solid $danger;
	}
}


.is-invalid.date-picker.form-group {
	// .form-group {
	// 	border: 1px solid $danger;
	// 	border-radius: 0.357rem;
	// }
	// .ant-picker.form-control.is-invalid {
	// 	border-left: none;
	// }
	// .input-group-prepend {
	// 	border: 1px solid $danger;
	// 	border-radius: 0.357rem;
	// 	border-right: none;
	// }
	// .form-control.is-invalid,
	// .input-group-text {
	// 	border: 1px solid transparent;
	// }

	.input-group-text {
		border-top: 1px solid $danger;
		border-left: 1px solid $danger;
		border-bottom: 1px solid $danger;
		border-right: 1px solid transparent;
	}
}

.form-group {

	.ant-picker,
	.ant-picker:hover,
	.ant-picker-focused {
		border: 1px solid #d8d6de;
		border-radius: 0.357rem;
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.ant-picker-input>input {
		color: #6e6b7b;
	}

	.ant-picker.ant-picker-range.form-control {
		border: 1px solid #d8d6de;
		border-radius: 0.357rem;
	}
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background-color: $primary !important;
}

.ant-picker-header-view button:hover {
	color: $primary !important;
}

.ant-picker.ant-picker-disabled {
	background-color: transparent !important;
	border-color: #3b4253 !important;
	opacity: 0.5;
}

.margin-top-half {
	margin-top: .5rem;
}

.media-body>a {
	margin-top: .5rem;
}

.secondary-color {
	color: $secondary !important;
}

.card-action.card .card-body {
	max-height: 500px;
	overflow-y: auto;
}

// .ui-loader {
// 	display: flex;
// 	flex: 1;
// 	.card-action.card {
// 		display: flex;
// 		flex: 1;
// 		background-color: transparent;
// 	}
// }

.no-shadow {
	box-shadow: none !important;
}

html .content.app-content {
	min-height: 100vh;
}

@media print {
	.main-menu {
		display: none !important;
	}

	.brand-logo {
		display: none !important;
	}

	html .content {
		margin: 0;
	}

	.hidden-print {
		display: none !important;
	}

	.full-width-print {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}

	html .content.app-content {
		padding: 0 !important;
	}

	.navbar-floating .header-navbar-shadow {
		display: none !important;
	}
}

.col-sm-6>.media {
	display: flex;
	flex-direction: column;
}

html[dir=rtl] {
	body {
		direction: rtl;
	}

	ul {
		padding-inline-start: 0;
	}

	@media screen and (min-width: 1200px) {

		.app-content.content {
			margin-left: 0;
			margin-right: 260px;
		}
	}


	.vertical-layout.vertical-menu-modern.menu-collapsed .app-content,
	.vertical-layout.vertical-menu-modern.menu-collapsed .footer {
		margin-left: 0;
		margin-right: 72px;
	}

	.header-navbar.floating-nav {
		right: auto;
		left: 0;
	}

	.ml-auto {
		margin-right: auto !important;
		margin-left: initial !important;
	}

	.mr-auto,
	.mx-auto {
		margin-right: initial !important;
		margin-left: auto !important;
	}

	.vertical-layout.vertical-menu-modern .main-menu .navigation>li>a svg,
	.vertical-layout.vertical-menu-modern .main-menu .navigation>li>a i {
		margin-right: initial !important;
		margin-left: 1.1rem !important;
	}

	.float-left {
		float: right !important;
	}

	.float-right {
		float: left !important;
	}

	.content .content-wrapper .content-header-title {
		border-right: none;
		padding-right: initial;
		padding-left: 1rem;
		border-left: 1px solid #d6dce1;
	}

	.breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item+.breadcrumb-item:before {
		margin-right: initial;
		margin-left: 0.6rem;
	}

	.breadcrumb-item+.breadcrumb-item {
		margin-left: initial;
		margin-right: .6rem;
	}

	.vertical-layout.vertical-menu-modern.menu-expanded .footer {
		margin-left: 0;
	}

	.vertical-overlay-menu .main-menu,
	.vertical-overlay-menu.menu-hide .main-menu {
		left: initial;
		right: -260px;
	}

	.vertical-overlay-menu.menu-open .main-menu {
		transform: translate3d(-260px, 0, 0);
	}

	// .main-menu.menu-fixed {
	// 	right: 0;
	// }
}

.site-logo {
	margin-top: 5rem;
	margin-bottom: 5rem;
}

#map {
	width: 100%;
	height: 100%;
	min-height: calc(100vh);
	cursor: auto !important;
}

.color-picker {
	height: 35px;
	width: 45px;
	padding: 0;
	border-radius: 4px;
	border: 1px solid #d9d9d9 !important;

	&:hover,
	&:focus {
		border: 1px solid #d9d9d9 !important;
	}
}

.colors-warp {
	width: 150px;
	display: flex;
	flex-wrap: wrap;

	.color-box {
		width: 37.5px;
		height: 37.5px;
		margin-bottom: 5px;
		text-transform: capitalize;
		cursor: pointer;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&.selected {
			background: #f5f5f5;
		}

	}

	&.inline {
		width: 100%;
		overflow: scroll;
		flex-wrap: nowrap;

		.color-box {
			flex: none;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.rc-virtual-list-holder {
	overflow: scroll !important;
	// &::-webkit-scrollbar {
	// 	display: none;
	// }
}

.content-wrapper {
	min-height: 80vh;
}

.map-page {
	min-height: 80vh;

	>div {
		min-height: 80vh;

	}
}

.my-location-button {

	background: none rgb(255, 255, 255);
	border: 0px;
	margin: 10px;
	padding: 0px;
	text-transform: none;
	appearance: none;
	position: absolute;
	cursor: pointer;
	user-select: none;
	border-radius: 2px;
	height: 40px;
	width: 40px;
	box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
	overflow: hidden;
}

// .link {
//     fill: none;
//     stroke: #ccc;
//     stroke-width: 2px;
// }

.add-form {
	flex: 1;
}

.btn-secondary.link-button {
	background-color: transparent !important;
	color: var(--text-color) !important;
	padding: 0 1rem !important;
	border: none !important;
	font-size: .8rem !important;

	&:hover {
		box-shadow: none !important;
		background-color: transparent !important;
	}
}

.btn-outline {
	width: 100%;
	background-color: transparent !important;
	border-color: var(--border-color) !important;
	color: var(--input-text-color) !important;
	text-align: initial;

	&:hover,
	&:focus {
		background-color: transparent !important;
		border-color: var(--border-color) !important;
		color: var(--input-text-color) !important;
		box-shadow: none !important;
	}
}

body.dark-layout {
	.ant-skeleton-content {
		background-color: var(--border-color);
	}

}




.chart {


	:root {
		--light-silver: #efefef;
		--white: #fff;
		--syntax_normal: #1b1e23;
		--syntax_comment: #a9b0bc;
		--syntax_number: #20a5ba;
		--syntax_keyword: #c30771;
		--syntax_atom: #10a778;
		--syntax_string: #008ec4;
		--syntax_error: #ffbedc;
		--syntax_unknown_variable: #838383;
		--syntax_known_variable: #005f87;
		--syntax_matchbracket: #20bbfc;
		--syntax_key: #6636b4;
		--mono_fonts: 82%/1.5 Menlo, Consolas, monospace;
		--out-color: #d8d8d8;
	}

	.tooltip-wrapper .hide {
		display: none;
	}

	.tooltip-wrapper .grid polygon {
		stroke: #171620;
	}

	.tooltip-wrapper #marker-square,
	.tooltip-wrapper #marker-arrow {
		fill: #7568af;
	}

	.tooltip-wrapper polyline {
		stroke: #7568af;
	}

	.tooltip-wrapper .tooltip-wrapper .profile {
		stroke: #a49fef;
	}

	.tooltip-wrapper .top-face {
		fill: #433f81;
		stroke: #322f68;
	}

	.tooltip-wrapper .right-face {
		fill: #322f68;
		stroke: #322f68;
	}

	.tooltip-wrapper .left-face {
		fill: #39366e;
		stroke: #322f68;
	}

	.tooltip-wrapper text tspan {
		fill: #cac6ff;
		stroke: #433f81;
	}

	.tooltip-wrapper text tspan+tspan {
		fill: #cac6ff;
		stroke: none;
	}

	.node:hover {
		cursor: pointer;
	}

	svg {
		cursor: grab;
	}

	.node polygon {
		fill: #ccc;
		stroke: #666;
		stroke-width: 0.5;
	}

	text {
		font-family: helvetica, sans-serif;
		font-weight: 700;
	}

	.shadow {
		color: #fff;
		stroke: #fff;
		stroke-width: 8;
	}

	.node:hover polygon {
		fill: red;
		stroke: #fff;
		stroke-width: 1;
	}

	.tooltip {
		background: white;
		box-shadow: inset 0 0 5px #999999;
		color: #333;
		/* position: absolute; */
		font-size: 12px;
		text-align: center;
		height: 100%;
		z-index: 10;
		text-align: left;
		margin: 0 6px;
	}

	.tooltip-wrapper {
		font-family: "Roboto", sans-serif;
		opacity: 0;
		display: none;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		padding: 20px;
		background: var(--out-color);
	}

	.header {
		display: flex;
		border-bottom: 1px solid var(--light-silver);
		position: absolute;
		/* height: 58px; */
		left: 0;
		right: 0;
		top: 0;
		z-index: 12;
		transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		background-color: var(--white);
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
	}

	.header #close {
		order: 4;
		background: white;
		padding: 8px;
		cursor: pointer;
	}

	.header .title {
		order: 0;
	}

	.div-up {
		position: relative;
		padding: 20px;
		background: var(--light-silver);
		margin: 0 -21px;
		margin-top: 35px;
		z-index: 13;
	}

	.div-up::before {
		position: absolute;
		right: 0;
		top: 0;
		content: "";
		border-left: 26px solid transparent;
		border-bottom: 40px solid var(--out-color);
	}

	.div-up::after {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 26px solid transparent;
		border-bottom: 40px solid var(--out-color);
		content: "";
	}

	.draw-area {
		height: 84vh;
	}

	.footer {
		display: flex;
		border-top: 1px solid var(--light-silver);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 12;
		transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		background-color: var(--white);
		align-items: center;
		justify-content: space-between;
		padding: 18px 15px;
	}
}

.p-absolute {
	position: absolute;
}

.left-0 {
	left: 0;
}

.right-0 {
	right: 0;
}

.top-0 {
	top: 0;
}

.draw-form-wrapper {
	z-index: 99;
	background-color: var(--background-color);

}

.view-wrapper {
	display: flex;
	position: relative;
	flex: 1;
	flex-direction: column;
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.space-between {
	justify-content: space-between;
}

.map-loader {
	position: absolute;
	z-index: 5;
	top: .5rem;
	left: 1.5rem;

	>div {
		padding-bottom: 0 !important;
		padding-top: 0 !important;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.spinner-border {
		border-right-color: red !important;
	}
}

.center-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1
}

.hidden-overflow {
	overflow: hidden;
}


.space-around {
	justify-content: space-around;
}

.justify-end {
	justify-content: flex-end;
}

.select-product {
	display: flex;
	align-items: center;
}

.grey {
	color: #666666 !important;
	margin: 0 .5rem;
}

.toast-wrapper {
	max-height: 600px;
	overflow-y: auto;
}

body.fullscreen .content.app-content {
	min-height: 100vh;
	padding: 1rem;
	margin: 0 !important;
}

body.fullscreen .header-navbar-shadow,
body.fullscreen footer {
	display: none;
}

body.fullscreen #map {
	height: calc(100vh - 120px);
}

.gm-control-active,
.gm-ui-hover-effect {
	&:focus {
		border: none;
		box-shadow: none;
		outline: none;
	}
}

.ant-input {
	border-radius: 5px;
}

.ant-input-affix-wrapper {
	padding: 6px 11px;
	border-radius: 5px
}

.add-panal {
	position: absolute;
	width: 300px;
	z-index: 1;
	background: white;
	padding: 20px 0;
	height: 100%;
	right: 0;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.drawing-controls {
	user-select: none;
	box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
		0 1px 3px 1px rgba(60, 64, 67, 0.15);
	border-radius: 2px;
	cursor: pointer;
	background-color: rgb(255, 255, 255);

	&.vertical {
		width: 55px;
		border-radius: 8px;
		// li:last-child:before {
		// 	display: none;
		// }
	}

	&.out-box {
		position: fixed;
		right: 10px;
		top: 120px;
		z-index: 1;
	}

	.product-types {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			position: relative;
			text-align: center;
			// padding: 3px 0;

			&:hover {
				color: black;
			}

			.action-icon {
				align-items: center;
				display: flex;
				justify-content: center;
			}

			&.active {

				// background: whitesmoke;
				// color: black;
				.action-icon {
					background-color: rgb(243, 243, 243);
				}
			}

			&:not(.type-item-head) {
				height: 55px;
				width: 55px;
				display: flex;
				justify-content: center;
				align-items: center;

				button {
					padding: 0;
					color: inherit;
				}

				svg {
					height: 20px;
				}
			}

			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	bottom: 0;
			// 	height: 1px;
			// 	width: 82%;
			// 	background-color: #eee;
			// 	margin: auto;
			// 	left: 0;
			// 	right: 0;
			// }
		}
	}

	&:not(.vertical) {
		height: 55px;
		// display: flex;
		padding: 0 10px;

		.product-types {
			display: flex;
		}

		li {
			&::before {
				width: 1px !important;
				height: 82% !important;
				top: 0;
				bottom: 0;
				left: auto;
				right: 0;
			}
		}
	}
}

.project-settings {
	a {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 15px;
		right: 10px;
		z-index: 1;
		background-color: white;
		border-radius: 2px;
		width: 42px;
		height: 42px;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
		color: inherit;

		&:hover {
			color: black;
		}
	}
}

.ant-message-custom-content {
	align-items: center;
	justify-content: center;
	display: flex;
}

.gm-ui-hover-effect {
	display: none !important;
}

.gm-style-iw-t {
	bottom: 33px !important;
}

.gm-style-iw-d {
	overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
	padding: 0 !important;
}

.gm-style .gm-style-iw-ch {
	padding: 0 !important;
}

.point-warp {
	cursor: pointer;
	height: 25px;
	width: 25px;

	img {
		cursor: pointer;
		position: absolute;
		top: 12px;
	}

	svg {
		cursor: pointer;
		position: absolute;
		top: 12px;
		color: white;
		stroke: white;
	}

	&.highlight {
		background: rgb(255 255 255 / 81%);
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5%;
		// width: 60px;
		// height: 60px;
		border: 2px solid white;
	}
}

.distance-tip {
	position: absolute;
	// background: blue;
	border-radius: 15px;
	font-size: 12px;
	padding: 5px 10px;
	// min-width: 35px;
	color: white;
	text-align: start;
}

.editor-warp {
	width: 340px;

	.ant-statistic-content {
		white-space: nowrap;
	}

	.ant-statistic-content-value,
	.ant-statistic-content-suffix {
		font-size: 14px;
		font-weight: 700;
	}

	.editor-header {
		display: flex;
		justify-content: space-between;
		padding: 12px 12px 8px;
		background-color: #f8f8f8;

		.editor-title {
			font-size: 16px;
			font-weight: 700;
			color: #000;
		}

		.editor-close {
			width: 19px;
			height: 19px;
			cursor: pointer;

			&:hover {
				color: #000;
			}
		}
	}

	.editor-body {
		min-height: 50px;
		padding: 12px;
	}

	.editor-footer {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #efefef;

		.editor-actions {
			display: flex;

			.editor-action {
				cursor: pointer;
				position: relative;

				&:not(&:last-child) {
					margin-right: 12px;
				}
			}

			svg {
				width: 18px;
				height: 18px;
				cursor: pointer;
				stroke-width: 2.5px;

				&:hover {
					color: #000;
				}
			}
		}
	}
}

.context-menu {
	position: absolute;
	min-width: 200px;
	// height: 200px;
	transform: translate(-50%, -50%);
	left: -100%;
	user-select: none;
	box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	border-radius: 2px;
	background-color: rgb(255, 255, 255);
	z-index: 1;
	&.is-mobile{
		transform: none;
		left: 0;
		right: 0;
		bottom: 50px;
		padding-bottom: 20px;
		.menu-items{
			min-height: 350px;
		}
	}
	.menu-header {
		display: flex;
		align-items: center;
		font-weight: 700;
		padding: 10px;
		border-bottom: 1px solid #eee;
		justify-content: space-between;

		.menu-title {
			svg {
				width: 25px;
				margin-right: 5px;
			}
		}
	}

	.menu-items {
		padding: 10px;
		max-height: 350px;
		overflow: hidden;
		overflow-y: scroll;

		.menu-item {
			cursor: pointer;
			display: block;
			font-size: inherit;
			width: 100%;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			background-color: transparent;
			color: inherit;
			padding: 8px 12px;
			box-sizing: border-box;

			&:hover {
				background-color: whitesmoke;
				color: #000;
			}

			&.focused {
				background-color: #DEEBFF;
				color: #000;
			}
		}
	}
}

.network-panel {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 307px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	overflow: hidden;

	.ant-collapse-header {
		padding: 0 !important;
	}

	.ant-collapse {
		border: none;

		.ant-list-header {
			padding: 12px;
		}

		.ant-collapse-content-box {
			padding: 0;
		}

		.ant-collapse-item-disabled>.ant-collapse-header {
			cursor: inherit;
			color: inherit;
		}
	}

	.network-panel-layers-head {
		background-color: #eee;
		padding: 8px 12px;
		display: flex;
		justify-content: space-between;

	}

	.network-panel-header {
		background-color: white;

		.title {
			padding: 5px;
			display: block;

			div:first-of-type {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			div:nth-of-type(2) {
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}

			input {
				font-size: 16px;
				font-weight: 600;

			}

			span {
				opacity: 0.5;
				text-transform: capitalize;
				font-size: 12px;
				cursor: pointer;
				margin-top: -25px;

				&:hover {
					color: #000;
					opacity: 1;
				}

				svg {
					width: 17px;
				}
			}
		}

		.actions {
			display: flex;
			justify-content: space-around;
			font-size: 12px;
			background: #eee;
			padding: 6px 10px;
			cursor: pointer;

			.action {
				display: flex;
				align-items: center;
				opacity: 0.7;

				span {
					margin: 0 5px;
				}

				&:hover {
					opacity: 1;
				}
			}

			svg {
				width: 17px;
			}
		}
	}

	.ant-collapse-content {
		border-top: 0;

		.ant-avatar-image {
			padding: 4px;
		}

		.ant-avatar>img {
			filter: invert(1);
		}

		.ant-list-item-meta {
			align-items: center;
		}

		.ant-list-item {
			padding: 3px 0;
		}
	}

	.ant-list-item {
		cursor: pointer;
	}
}

.location-control {
	width: 55px;
	height: 55px;
	background-color: white;
	position: absolute;
	text-align: center;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	z-index: 1;
	right: 10px;
	bottom: 119px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		color: black;
		background: #eee;
	}

	&.active {
		color: #4d90fe;

	}
}

.current-location {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;

	.wave {
		width: 50px;
		height: 50px;
		background: blue;
		border-radius: 100%;
		position: absolute;
	}

	.pin {
		width: 20px;
		height: 20px;
		width: 20px;
		height: 20px;
		position: absolute;
		background: blue;
		border: 2px solid white;
		border-radius: 100%;
	}
}

.wave {
	animation: wave 2s infinite;
}

@keyframes wave {
	0% {
		transform: scale(0.8);
		opacity: 0.8;
	}

	50% {
		transform: scale(1.2);
		opacity: 0.5;
	}

	100% {
		transform: scale(0.8);
		opacity: 0.8;
	}
}

.camera-position {
	width: 40px;
	height: 42px;
	background-color: white;
	position: absolute;
	text-align: center;
	border-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	right: 10px;
	bottom: 119px;
	z-index: 1;

	div:first-of-type {
		width: 100%;
		height: 100%;

		svg {
			width: 21px;
			position: absolute;
			cursor: pointer;
			stroke-width: 3px;

			&:hover {
				color: black;
			}
		}

		svg:first-of-type {
			left: 0;
			right: 0;
			top: -5px;
			margin: auto;
		}

		svg:nth-of-type(2) {
			left: -4px;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		svg:nth-of-type(3) {
			right: -4px;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		svg:nth-of-type(4) {
			left: 0;
			right: 0;
			bottom: -5px;
			margin: auto;
		}
	}
}

.ant-popover-message-icon {
	margin-top: -4px;
}
.ant-popover{
	z-index: 1051;
}
.notice-warp {
	max-width: 261px;
	display: flex;
	flex-wrap: nowrap;
	font-size: 12px;
	align-items: center;

	svg {
		flex: none;
		margin-right: 10px;
	}
}

.animate-warp {
	position: relative !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.animate-ping {
	animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-badge {
	display: flex;
	position: relative;
	height: 8px;
	width: 8px;

	.ping-item {
		position: absolute;
		display: inline-flex;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		background: #10a778;
		opacity: 0.75;
	}

	.ping-circle {
		position: relative;
		display: inline-flex;
		border-radius: 100%;
		height: 8px;
		width: 8px;
		background: #10a778;
		align-items: center;
		justify-content: center;
		color: white;

		svg {
			stroke-width: 4;
			width: 9px;
		}
	}
}

.message-content {
	.message-warp {
		display: flex;
		align-items: center;
	}

	.message-icon {
		margin-left: 10px;
	}
}

.warning-ping {
	width: 16px;
	height: 16px;
	position: absolute;
	text-align: center;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0.5px;
	top: 0;
	opacity: 0.5;
}

@keyframes ping {

	75%,
	100% {
		transform: scale(2);
		opacity: 0;
	}
}

.map-view {
	height: 69px;

	.item {
		width: 60px;
		height: 60px;
		margin: 4px;
		border: 1px solid #ccc;
		text-align: center;
		float: left;
		overflow: hidden;
		z-index: 1000;
		position: relative;
		background-color: #fff;
		cursor: pointer;
		border-radius: 5px;

		.image {
			border: 1px solid white;
			width: 60px;
			height: 60px;
			display: inline-block;
			margin: -1px 0 0 -1px;
			overflow: hidden;
			background-color: #f1f1f1;
			background-image: url(//www.gstatic.com/mapspro/images/basemap-thumbs000.png);
			-webkit-background-size: 210px;
			background-size: 210px;
		}

		&.roadmap {
			.image {
				background-position: -5px -5px;
			}
		}

		&.hybrid {
			.image {
				background-position: 135px -5px;
			}
		}

		&.terrain {
			.image {
				background-position: 65px -5px;
			}
		}

		&.active {
			border: 2px solid #607D8B;
		}

	}
}

@media (max-width: 576px) {
	.editor-warp {
		width: 270px !important
	}
}

@media (max-width: 768px) {
	.editor-warp {
		width: 270px !important
	}
	.location-control{
		bottom: 35% !important;
	}
}

.page-header {
	padding: 15px 20px;
	justify-content: space-between;
	display: flex;
	background-color: $white;
	border-bottom: 1px solid $border-color;
}

.ant-card-bordered .ant-card-cover {
	max-height: 194px;
	overflow: hidden;

	&::before {
		background: linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0) 68px);
		height: 165px;
		position: absolute;
		top: 0;
		content: '';
		width: 100%;
	}
}

.pro-loadmore-list {
	&:not(.ant-list-grid) {
		.ant-list-item {
			border: none;
			cursor: pointer;
			position: relative;
		}

		.ant-list-item-action {
			position: absolute;
			right: -3px;
			top: 11px;
		}

		.ant-card-extra {
			margin-right: 6px;
		}
	}

	&.ant-list-grid {
		.ant-list-item-action {
			position: absolute;
			right: 5px;
			top: 5px;

			>li {
				color: white;
			}
		}
	}

	.assigned-users {
		display: flex;
		flex: 1 1;
		align-items: center;

		.ant-avatar {
			box-shadow: 0px 0px 0px 4px #fff;
			display: inline-block;
			overflow: hidden;
			vertical-align: middle;
			cursor: pointer;
			margin-left: -2px;
		}

		.more {
			background-color: #047bf8;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			left: -10px;
			padding: 2px 7px;
			border-radius: 12px;
			color: #fff;
			font-size: .63rem;
		}
	}

	.stats-pro {
		display: flex;
		flex: 2 1;
		align-items: center;
		justify-content: space-around;

		.stat-label {
			display: block;
			font-size: .63rem;
			text-transform: uppercase;
			color: rgba(0, 0, 0, 0.4);
			letter-spacing: 1px;
		}

		.stat-value {
			font-size: 2rem;
			font-weight: 500;
			letter-spacing: 1px;
			line-height: 1.2;
			display: inline-block;
			vertical-align: middle;
			color: #047bf8;
		}
	}
}

.stat-card {
	.ant-card-head {
		border: none;
	}
}

.data-drawer {
	margin-left: 72px;

	.ant-drawer-content-wrapper {
		width: 400px;
	}

	.ant-drawer-body {
		padding: 0;
	}

	.ant-drawer-header {
		padding: 8px 23px;
		min-height: 63px;
	}

	.ant-drawer-close {
		margin: 0;
		margin-left: -23px;
	}
}

.ant-drawer-close {
	&:focus {
		outline: none;
	}
}

.warp-scroll {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 70px);

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}

	scrollbar-width: none;
	-ms-overflow-style: none;
}

.side-list {
	.ant-list-item {
		padding: 4px 0;
		min-height: 49px;
		transition: all .15s ease-in-out;
		cursor: pointer;

		&:hover {
			background-color: rgb(243 243 243 / 40%);

			.ant-list-item-action {
				transition: display .15s ease-in-out;
				display: block;
				opacity: 1;
			}

			// .ant-list-item-meta-description{
			// 	animation-play-state: running;
			// }
		}
		// .ant-list-item-meta-content{
		// 	white-space: nowrap;
		// 	animation: ticker 10s linear infinite; /* Adjust the duration as needed */
		// 	animation-play-state: paused;
		// }

		.ant-list-item-meta {
			align-items: center;
		}
		.ant-list-item-meta-description{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			transition: all 0.5s;

		}
		.ant-list-item-meta-title {
			font-weight: 600;
			text-transform: capitalize;
		}
	}

	.ant-list-item-action {
		// display: none;
		margin-left: 0;;
		opacity: 0.5;

		li {
			padding: 0;
		}
	}

}

@keyframes ticker {
	0% {
	    transform: translateX(100%);
	}
	100% {
	    transform: translateX(-100%);
	}
 }

.action-icon {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	transition: all .15s ease-in-out;
	user-select: none;
	cursor: pointer;
	z-index: 0;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	padding: 7px;
	// margin-right: 3px;

	&:hover {
		background-color: rgb(243 243 243);
		color: #000;
	}
}

.tulip-button {
	border-radius: 4px;
	background-color: #20c997;
	color: white;
	border: none;

	&:hover,
	&:focus {
		background-color: darken(#20c997, 5%);
		color: white;
		border: none;
	}
}

.tulip-button-light {
	border-radius: 4px;
	background-color: white;
	color: black;
	border: 1px solid #eee;

	&:hover,
	&:focus {
		background-color: darken(white, 5%);
		color: black;
		border: 1px solid #eee;
	}
}

.loader-line {
	width: 100%;
	height: 2px;
	position: relative;
	overflow: hidden;
	background-color: rgb(243 243 243);
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.loader-line:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 3px;
	width: 40%;
	background-color: #20c997;
	-webkit-animation: lineAnim 1s linear infinite;
	-moz-animation: lineAnim 1s linear infinite;
	animation: lineAnim 1s linear infinite;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

@keyframes lineAnim {
	0% {
		left: -40%;
	}

	50% {
		left: 20%;
		width: 80%;
	}

	100% {
		left: 100%;
		width: 100%;
	}
}

@media (max-width: 768px) {
	.data-drawer {
		.ant-drawer-content-wrapper {
			width: 100vw;
		}

		width: 100vw;
		margin-left: 0;
	}

	.network-panel {
		left: 0;
		width: auto;
		right: 0;
		top: 0;
		border-radius: 0;
	}

	.drawing-controls {
		height: auto !important;
		bottom: 0;
		top: auto !important;
		left: 0;
		right: 0 !important;
		border-radius: 0;
		padding-bottom: 18px !important;
	}
}


.search-panal {
	.ant-collapse-header {
		padding: 0 !important;
	}

	.ant-list-header {
		padding: 12px;
	}

	.ant-collapse {
		border: none !important;
		border-radius: 0 !important
	}

	.network-panel-layers-head {
		display: flex;
		justify-content: space-between;
		padding: 12px;
	}

	.ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-collapse-content .ant-list-item {
		padding: 3px 0;
		cursor: pointer;
	}

	.ant-collapse>.ant-collapse-item:last-child,
	.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
		border-radius: 0 !important
	}

	.ant-collapse>.ant-collapse-item {
		border-color: #ebe9f1;
	}
}
.as-soon{
	opacity: 0.4;
}
.soon{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgb(89 85 85 / 21%);
	z-index: 1;
	// font-size: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	.text-soon{
		background-color: white;
		padding: 5px 15px;
		border-radius: 50%;
		border-radius: 53px;
		color: #c30771;
	}
	.warp-soon{
		position: absolute;
		
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.ant-drawer-body{
	&::-webkit-scrollbar {
		display: none;
	}
}

.w-115{
	width: 116px;
}
.is-loading{
	display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.keyboard-shortcut{
	list-style: none;
	li{
		margin-bottom: 10px;
	}
}
.ant-modal-close{
	&:focus{
		outline: none;
		border: none;
	}
}

.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	pointer-events: none; /* Disables pointer events during loading */
   }

.share-select{
	.select__indicators{
		display: none;
	}
}

.share-box{
	.side-list .ant-list-item{
		padding-left: 0 !important;
	}
	.infinite-scroll-component {
		max-height: 200px !important;
		&::-webkit-scrollbar{
			display: none;
		}
	}
	.share-title{
		font-weight: 700;
		color: #000;
		padding: 5px 0 ;
	}
	.ant-list-empty-text{
		display: none;
	}

	.side-list .ant-list-item .ant-list-item-meta-title{
		font-weight: normal;
	}
}

.w-20{
	width: 30%;
}

.w-80{
	width: 70%;
}
.w-100{
	width: 100%;
}
.disabled-item{
	pointer-events: none;
    opacity: 0.5;
}

.shared-point{
	background: white;
	width: 20px;
	height: 20px;
	z-index: 9;
	position: absolute;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #eee;
	top: -8px;
	right: 0px;
	left: 0;
	margin: auto;
	padding: 2px;
    svg{
	position: initial;
	cursor: pointer;
	color: #28c76f;
	stroke: #28c76f;
	width: 15px;
    }

}

.advanced-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-family: Arial, sans-serif;
	text-align: left;
	margin-bottom: 10px;
	color: #3E4B5B;
   }
   
   .advanced-table td {
	border-top: 1px solid rgba(83,101,140,0.08);
	padding: 0.35rem;
	font-size: .9rem;
	font-weight: 400;
	line-height: 1.5;
	vertical-align: middle;
   }
   
//    .advanced-table th {
// 	background-color: #f2f2f2;
// 	font-weight: bold;
//    }
   
//    .advanced-table tr:nth-child(even) {
// 	background-color: #f2f2f2;
//    }
   
//    .advanced-table tr:nth-child(odd) {
// 	background-color: #ffffff;
//    }
   
   .advanced-table th, .advanced-table td {
	text-align: left;
   }
   
   .advanced-table th {
	font-size: .83rem;
    text-transform: uppercase;
    border-top: none;
    padding: 0.75rem;
   }
   
   .advanced-table td {
	font-size: 14px;
   }
   
   .bt{
	border-top: 1px solid rgba(94,101,140,0.33);
   }
.draw-control{
	position: absolute;
	top: 65px;
	left: 10px;
	right: 10px;
	display: flex;
	justify-content: space-between;
	.control-item{
		background: white;
		padding: 10px 19px;
		border-radius: 12px;
		margin: 0 5px;
		&.disabled-control{
			color: gray;
		}
	}
}

.drop-link{
	padding: 10px;
	&:hover{
		cursor: pointer;
		color: red;
	}
}
.icon-restricted{
	color: #838383;
    background: #eeee;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.select-access.ant-select-focused:not(.ant-select-disabled).select-access.ant-select:not(.ant-select-customize-input) .ant-select-selector{
	border: none;
	outline: none;
	box-shadow: none;
}
.select-access:not(.ant-select-customize-input) .ant-select-selector{
	border: none;
	&:focus{
		border: none;
		outline: none;
		box-shadow: none;
	}
}

.path-link{
	.ant-radio-group{
		width: 100%;
	}
	.ant-radio-button-wrapper{
		flex: 1;
		text-align: center;
		font-weight: 500;
	}
}